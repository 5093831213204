import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-box',
  templateUrl: './top-box.component.html',
  styleUrls: ['./top-box.component.css']
})
export class TopBoxComponent implements OnInit {
  @Input() title = "";
  @Input() subText = "";

  constructor() { }

  ngOnInit() {
  }

}
